import React from 'react';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import ClickableElement from '../../molecules/ClickableElement/ClickableElement';
import OptimizedImage from '../../utils/OptimizedImage';
import { Body, Container, ImageWrapper, Text } from './styles/SolutionsCard.styled';

const SolutionsCard = ({ heading, subhead, link, image }) => {
  let label = '';

  if (heading) {
    label = heading;
  } else if (link.label) {
    label = link.label;
  } else {
    label = 'Learn More';
  }

  return (
    <Container>
      {link?.url && <ClickableElement url={link.url} aria-label={label} />}
      <ImageWrapper>
        {image?.gatsbyImageData && (
          <OptimizedImage
            image={image.gatsbyImageData}
            alt={image?.alt}
            title={image?.title}
          />
        )}
      </ImageWrapper>
      <Body>
        <Text>
          {heading && <h3 className="solutions-card-heading">{heading}</h3>}
          {subhead && (
            <div
              className="solutions-card-subhead"
              dangerouslySetInnerHTML={{ __html: subhead }}
            />
          )}
        </Text>
        {link?.url && (
          <CallToAction
            variant="linkGray"
            link={link.url}
            value={link?.label}
            icon="right"
          />
        )}
      </Body>
    </Container>
  );
};

export default SolutionsCard;
